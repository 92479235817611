import React, { useState, useReducer, useEffect } from "react";
import { themeConfig } from "./ThemeContext";
import { translationsConfig } from "./LanguageContext";
import cssVars from "css-vars-ponyfill";
//CLAIMID????
export const defaultState = {
  isLoading: false,
  config: {},
  theme: themeConfig["metromile"],
  translations: translationsConfig,
  phoneSearchDispatch: () => {},
  claimDetailsDispatch: () => {},
  errorDispatch: () => {},
  setIsLoading: () => {},
  phoneSearchResults: {},
  claimDetails: {}
};

const phoneSearchResultsDefaults = {
  data: null,
  pending: false,
  status: null,
  error: false,
  complete: false
};

const errorDefaults = {
  error: null
};

export const phoneSearchReducer = (state, action) => {
  switch (action.type) {
    case "update":
      return {
        ...state,
        ...action.value,
        routeTo: action.routeTo
      };
    default:
      return state;
  }
};

export const claimDetailsReducer = (state, action) => {
  switch (action.type) {
    case "update":
      return {
        ...state,
        ...action.value
      };
    default:
      return state;
  }
};

export const errorReducer = (state, action) => {
  switch (action.type) {
    case "update":
      return {
        ...state,
        error: action.value
      };
    default:
      return state;
  }
};

export const translationsReducer = (state, action) => {
  switch (action.type) {
    case "update":
      return {
        ...state,
        ...action.translations
      };
    default:
      return state;
  }
};

export const AppContext = React.createContext({});

const AppContextProvider = ({
  children,
  config = {},
  theme = themeConfig.metromile
}) => {
  const [isLoading, setIsLoading] = useState(defaultState.isLoading);
  const [translations, setTranslations] = useState(translationsConfig);

  const [phoneSearchResultState, phoneSearchDispatch] = useReducer(
    phoneSearchReducer,
    phoneSearchResultsDefaults
  );

  const [claimDetailsState, claimDetailsDispatch] = useReducer(
    claimDetailsReducer,
    {}
  );

  const [translationsState, translationsDispatch] = useReducer(
    translationsReducer,
    translations
  );

  const [errorState, errorDispatch] = useReducer(errorReducer, errorDefaults);

  const updateCSSVariables = config => {
    const variables = {
      "--theme-primary-color":
        config.data && config.data.colors.primary
          ? config.data.colors.primary
          : "",
      "--theme-secondary-color":
        config.data && config.data.colors.secondary
          ? config.data.colors.secondary
          : "",
      "--theme-background-color":
        config.data && config.data.colors.background
          ? config.data.colors.background
          : "",
      "--theme-primary-color-l1":
        config.data && config.data.colors.l1 ? config.data.colors.l1 : "",
      "--theme-color-error":
        config.data && config.data.colors.error
          ? config.data.colors.error
          : "#ec4a3b",
      "--theme-color-success":
        config.data && config.data.colors.success
          ? config.data.colors.success
          : "#86c955"
    };

    cssVars({
      variables
    });
  };

  useEffect(() => {
    if (config.data && config.data.translations) {
      setTranslations({ ...translationsConfig, ...config.data.translations });
    }
    updateCSSVariables(config);
  }, [config]);

  useEffect(() => {
    setTranslations(translationsState);
  }, [translationsState]);

  return (
    <AppContext.Provider
      value={{
        claimDetailsDispatch,
        claimDetailsState,
        phoneSearchDispatch,
        phoneSearchResultState,
        errorDispatch,
        errorState,
        translationsState,
        translationsDispatch,
        isLoading,
        theme,
        translations,
        setIsLoading
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
