import React from "react";

const currentLang =
  sessionStorage.getItem("lang") ||
  (navigator.language || navigator.userLanguage);

export const translationsConfig = {
  lang: currentLang,
  noFNOLMessage: "No FNOL could be found, please try a different phone number",
  phoneSearchBar: {
    arsButton: "ARS Link",
    claimSystemButton: "Send to claims system ▶",
    hint: "Telephone Number"
  },
  claimDetailsHeadings: "CLAIM ID,LAST UPDATED,SENT",
  errorMessage:
    "An error occurred while processing your request. Please try again. If the problem persists please contact enterprisesupport@tmnf.com"
};

const LangContext = React.createContext(translationsConfig);
export default LangContext;
