import React from "react";

export const themeConfig = {
  metromile: {
    colors: {
      primary: "#0dc0c9",
      secondary: "#ffffff",
      hoverState: "#f0fcfe",
      error: "#ec0044"
    }
  },
  tokiomarine: {
    colors: {
      primary: "#0074CE",
      secondary: "#000000",
      hoverState: "#0dc0c9",
      error: "#ec0044"
    }
  }
};

const ThemeContext = React.createContext(themeConfig.metromile);
export default ThemeContext;
