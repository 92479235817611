import React, {Fragment, useContext, useEffect, useMemo} from "react";
import "./ClaimDetails.scss";
import {useRouterMatch, useScript} from "../../helpers/Hooks";
import {GetFlowDetails} from "../../helpers/Api";
import {Accordion, Card, DataTable} from "ebs-component-library";
import uuidv4 from "uuid/v4";
import GoogleMaps from "../../components/GoogleMaps/GoogleMaps";
import {AppContext} from "../../context/AppContext";
import moment from "moment-timezone";

const API_KEY = "AIzaSyCCb9miKQPt99-pfiGzR3-d__oAe7RVJfM";

const ClaimDetails = () => {
  const { translations, claimDetailsDispatch } = useContext(AppContext);
  const { params } = useRouterMatch("/:guid");
  const [claims, getClaims] = GetFlowDetails();
  const [mapScriptloaded] = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places`
  );

  useEffect(() => {
    if (params && params.guid) {
      getClaims(params.guid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, translations]);

  useEffect(() => {
    if (claims.data) {
      claimDetailsDispatch({
        type: "update",
        value: claims.data
      });
    }
  }, [claimDetailsDispatch, claims]);

  const renderPhotos = objData => {
    if (!objData.photos) return;
    return (
      <span key={uuidv4()} className="accordion-photos">
        <span className="title">{objData.shortText}</span>
        <span className="value">
          {objData.photos.map(url => {
            return (
              <img
                key={url}
                src={url}
                className="photos"
                alt={objData.shortText}
              />
            );
          })}
        </span>
      </span>
    );
  };

  const hasAnswerData = data => {
    const answers = data.answers;
    if (!answers || Object.keys(answers).length === 0) {
      return false;
    }

    for (let key in answers) {
      if (answers[key].answered && answers[key].title === undefined) {
        return true;
      }
    }

    for (let key in answers) {
      return answers[key].answers && answers[key].answers.some(a => a.answered);
    }
  };

  const memoDetails = useMemo(() => {
    const renderMap = objData => {
      if (
        !mapScriptloaded ||
        isNaN(objData.answers.lat) ||
        isNaN(objData.answers.lng) ||
        objData.answers.lat === null ||
        objData.answers.lng === null
      ) {
        return;
      }
      return (
        <Fragment>
          <GoogleMaps lat={objData.answers.lat} lng={objData.answers.lng} />
        </Fragment>
      );
    };

    const renderAccordionAnswers = (obj, key) => {
      // console.log(`renderAccordionAnswers key -> ${key}`);
      // console.log(`renderAccordionAnswers obj -> ${JSON.stringify(obj)}`);
      const objData = obj.answers[key];

      if (!objData) {
        return;
      }

      let answerText = objData.answerText;
      let map = false;

      if (objData.type === "location") {
        answerText = objData.answers.other
          ? objData.answers.other
          : objData.answers.address;
        map =
          !objData.answers.other && Object.keys(objData.answers).length > 0
            ? true
            : false;
      }

      if (objData.photos) {
        return renderPhotos(objData);
      } else {
        return (
          <Fragment key={uuidv4()}>
            <span
              className={
                "accordion-information" +
                (objData.answers && objData.type !== "location"
                  ? " accordion-information-sub"
                  : "")
              }
            >
              <span className="title">
                {objData.shortText || objData.title}
              </span>
              <span
                className={
                  objData.answers && objData.type !== "location"
                    ? "sub-value value"
                    : "value"
                }
              >
                {objData.answers
                  ? iterateMoreAnswers(objData.answers)
                  : answerText}
              </span>
            </span>
            {map && renderMap(objData)}
          </Fragment>
        );
      }
    };

    const iterateMoreAnswers = obj => {
      return Object.keys(obj).map(id => {
        if (!obj[id]) return null;
        if (id === "components") {
          return obj["address"]
        } else {
          return obj[id].shortText === "" || obj[id].title !== "" ? (
            <span key={uuidv4()} className="accordion-sub-information">
              <span className="sub-title">
                {obj[id].shortText || obj[id].title}
              </span>
              <span className="value">{obj[id].answerText}</span>
            </span>
          ) : null;
        }
      });
    };

    const iterateObj = obj => {
      if (obj.answerOrder) {
        return obj.answerOrder.map(key => {
          return renderAccordionAnswers(obj, key);
        });
      } else {
        if (!obj.answers) {
          return;
        }
        for (var key in obj) {
          return renderAccordionAnswers(obj, key);
        }
      }
    };

    const formatValues = obj => {
      const formattedObj = iterateObj(obj);

      var merged = [].concat.apply([], formattedObj);
      return merged;
    };

    const renderEmptyState = () => {
      return <div className="details-empty-state">{claims.data.message}</div>;
    };

    const renderBasicDetails = () => {
      if (!claims.data || !claims.data.meta) {
        return;
      }

      const defaultTimeZone = moment.tz.guess(true);
      const systemTimeZone = "UTC";
      const momentTimeZone =
        translations.lang === "ja" &&
        claims.data.meta.data.lastUpdated.value !== null
          ? moment
              .tz(claims.data.meta.data.lastUpdated.value, systemTimeZone)
              .tz("Asia/Tokyo")
              .format("YY/MM/DD (HH:mm)")
          : moment
              .tz(claims.data.meta.data.lastUpdated.value, systemTimeZone)
              .tz(defaultTimeZone)
              .format("YY/MM/DD (hh:mm)");

      const rows = [
        [
          claims.data.meta.data.claimId.title,
          claims.data.meta.data.claimId.value
        ],
        [claims.data.meta.data.lastUpdated.title, momentTimeZone],
        [
          claims.data.meta.data.policyNumberIsFound.title,
          claims.data.meta.data.policyNumberIsFound.value
        ]
      ];
      return <DataTable columnTitles={[]} rows={rows} />;
    };

    return (
      <div className="claims-results-container">
        <Card loading={claims.pending} fadeIn>
          {claims.data && claims.data.error ? (
            renderEmptyState()
          ) : (
            <div className="claims-details-container">
              {renderBasicDetails()}
              {claims.data &&
                claims.data.sectionOrder &&
                claims.data.sectionOrder.map(section => {
                  const sectionData = claims.data.sections[section];
                  return (
                    <Accordion
                      className={!sectionData.complete ? "mustard" : ""}
                      key={uuidv4()}
                      defaultIsOpen={hasAnswerData(sectionData) || false}
                      placeholder={sectionData.title}
                    >
                      <div className="accordion-content-container">
                        {formatValues(sectionData)}
                      </div>
                    </Accordion>
                  );
                })}
            </div>
          )}
        </Card>
      </div>
    );
  }, [claims.data, claims.pending, mapScriptloaded, translations.lang]);

  return memoDetails;
};

export default ClaimDetails;
