import React, { useEffect, useState } from "react";
import "./Loader.scss";
export const Loader = ({ isLoading }) => {
  const [showLoading, setShowLoading] = useState(isLoading);

  useEffect(() => {
    const timer = setTimeout(() => setShowLoading(true), 500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className="spinner" hidden={!showLoading}>
      <div className="cube1" />
      <div className="cube2" />
    </div>
  );
};
