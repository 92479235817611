import React, {useContext} from "react";
import "./ClaimOverview.scss";
import {Card, DataTable} from "ebs-component-library";
import {Link as RouterLink} from "react-router-dom";
import {AppContext} from "../../context/AppContext";
import moment from "moment-timezone";

const ClaimOverview = () => {
  const { phoneSearchResultState, translations } = useContext(AppContext);

  const setClassName = status => {
    return status === true ? "sent" : "icon-x";
  };

  const rows = () => {
    const showClaims = phoneSearchResultState.data
      ? phoneSearchResultState.data
      : [];
    return Object.keys(showClaims).map(id => {
      const claim = showClaims[id];
      const lastUpdateWithTZ =
        moment.tz(claim.lastUpdate, "UTC").tz("Asia/Tokyo").format("YY/MM/DD (HH:mm)");
      return [
        <RouterLink className="claim-id-router-link" to={`/${claim.id}`}>
          {claim.claimId !== "null" ? claim.claimId : "N/A"}
        </RouterLink>,

        <div>{claim.lastUpdate ? lastUpdateWithTZ : "N/A"}</div>,
        <div className={setClassName(claim.sent)} />
      ];
    });
  };

  const renderEmptyState = () => {
    return (
      <div className="overview-empty-state">{translations.noFNOLMessage}</div>
    );
  };

  const destructureHeadinObj = () => {
    if (translations.claimDetailsHeadings) {
      return (([claimId, lastUpdated, sent]) => [claimId, lastUpdated, sent])(
        translations.claimDetailsHeadings.split(",").map(val => val)
      );
    }
  };

  return (
    <div className="search-results-container">
      <Card loading={phoneSearchResultState.pending} fadeIn>
        {phoneSearchResultState.data &&
          phoneSearchResultState.data.length > 0 ? (
            <DataTable columnTitles={destructureHeadinObj()} rows={rows()} />
          ) : (
            renderEmptyState()
          )}
      </Card>
    </div>
  );
};

export default ClaimOverview;
