import React, { useState, useEffect, useContext } from "react";
import "./Error.scss";

import { AppContext } from "../../context/AppContext";

const Error = () => {
  const { translations, errorState } = useContext(AppContext);
  const [hide, setHide] = useState(true);

  useEffect(() => {
    setHide(errorState.error !== null ? false : true);
  }, [errorState]);

  /* these japanese strings should come from the translations object. but there
   * really isn't any reason they should be changed/configurable, since this tool
   * is specifically for tm. To prevent unnecessary changes in be and vault, they
   * will live right here.
   */
  const successMsg = () => {
    return <span className="success">正常完了：情報連携が完了しました。</span>;
  };

  const erroMsg = () => {
    return (
      <span className="error">
        エラー　　 ：情報連携に失敗しました。再度ボタンをクリックして下さい。
      </span>
    );
  };

  const handleOnclick = () => {
    setHide(true);
  };

  return (
    <div
      className={
        "submit-error-container" +
        (hide ? " hide" : " show") +
        (errorState.error ? " error" : " success")
      }
    >
      {errorState.error ? erroMsg() : successMsg()}
      <div onClick={handleOnclick} className="icon-x-white" />
    </div>
  );
};

export default Error;
