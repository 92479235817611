import React from "react";
import ErrorPage from "./ErrorPage";
import * as Sentry from "@sentry/browser";

// There's no hook for catching errors *yet* so we need to create a class for this
// https://reactjs.org/docs/hooks-faq.html#do-hooks-cover-all-use-cases-for-classes

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
      this.setState({ hasError: true });
    });
  }

  render() {
    const { hasError } = this.state;
    const { onErrorRetry } = this.props;
    if (hasError) {
      return (
        <ErrorPage
          onRetryClick={
            onErrorRetry !== null
              ? () => {
                  onErrorRetry();
                  this.setState({ hasError: false });
                }
              : null
          }
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
