import { useEffect, useState, useContext } from "react";

import { RouterContext } from "./CustomBrowserRouter.js";
import { matchPath } from "react-router";

export const useRouter = () => {
  return useContext(RouterContext);
};

export const useRouterMatch = pathToMatch => {
  const router = useRouter();
  const [params, setParams] = useState({});

  useEffect(() => {
    const params = matchPath(router.location.pathname, {
      path: pathToMatch,
      exact: true,
      strict: false
    });
    if (!params) {
      return setParams("");
    }
    setParams(params);
  }, [router, pathToMatch]);

  return params;
};

// const waitFor = delay => new Promise(resolve => setTimeout(resolve, delay));

export function useEndpoint(fn) {
  const [response, setResponse] = useState({
    data: null,
    complete: false,
    pending: false,
    error: false
  });
  const [request, setRequest] = useState();

  useEffect(() => {
    if (!request) return;

    setResponse({ pending: true, error: false, complete: false });
    fetch(request.url, {
      headers: request.headers,
      method: request.method
    })
      .then(response =>
        response.json().then(json => {
          setResponse({
            data: json,
            pending: false,
            status: json.status,
            error:
              json.status === 200 || json.status === undefined ? false : true,
            complete: true
          });
        })
      )
      .catch(error => {
        setResponse({
          data: null,
          pending: false,
          error: true,
          complete: true
        });
      });
  }, [request]);
  return [response, (...args) => setRequest(fn(...args))];
}

let cachedScripts = [];
export const useScript = src => {
  // Keeping track of script loaded and error state
  const [state, setState] = useState({
    loaded: false,
    error: false
  });

  useEffect(
    () => {
      // If cachedScripts array already includes src that means another instance ...
      // ... of this hook already loaded this script, so no need to load again.
      if (cachedScripts.includes(src)) {
        setState({
          loaded: true,
          error: false
        });
      } else {
        cachedScripts.push(src);

        // Create script
        let script = document.createElement("script");
        script.src = src;
        script.async = true;

        // Script event listener callbacks for load and error
        const onScriptLoad = () => {
          setState({
            loaded: true,
            error: false
          });
        };

        const onScriptError = () => {
          // Remove from cachedScripts we can try loading again
          const index = cachedScripts.indexOf(src);
          if (index >= 0) cachedScripts.splice(index, 1);
          script.remove();

          setState({
            loaded: true,
            error: true
          });
        };

        script.addEventListener("load", onScriptLoad);
        script.addEventListener("error", onScriptError);

        // Add script to document body
        document.body.appendChild(script);

        // Remove event listeners on cleanup
        return () => {
          script.removeEventListener("load", onScriptLoad);
          script.removeEventListener("error", onScriptError);
        };
      }
    },
    [src] // Only re-run effect if script src changes
  );

  return [state.loaded, state.error];
};
