import React, { useContext } from "react";
import "./ErrorPage.scss";
import { AppContext } from "../../context/AppContext";

const ErrorPage = () => {
  const { translations } = useContext(AppContext);

  return (
    <div className="error-container">
      <div className="error">
        {translations
          ? translations.errorMessage
          : "エラーが発生しました。 ページを更新してください。"}
      </div>
    </div>
  );
};

export default ErrorPage;
