import React from "react";
import "./Header.scss";
import TelephoneSearch from "./TelephoneSearch/TelephoneSearch";
import ActionButtons from "./ActionButtons/ActionButtons";
import LanguageToggle from "./LanguageToggle/LanguageToggle";

const isDev = process.env.NODE_ENV === "development";

const Header = () => {
  return (
    <div className="header">
      <TelephoneSearch />
      <span className="second-row">
        <ActionButtons />
        {isDev && <LanguageToggle />}
      </span>
    </div>
  );
};

export default Header;
