import React, { useEffect } from "react";
import AdminPage from "./Page/AdminDash/AdminDash";
import { Loader } from "./helpers/Loader";
import { Switch, Route } from "react-router-dom";
import CustomBrowserRouter from "./helpers/CustomBrowserRouter";
import AppContextProvider from "./context/AppContext";
import { GetConfig } from "./helpers/Api";
import ErrorBoundary from "./components/ErrorPage/ErrorBoundary";

const App = () => {
  const [config, getConfig] = GetConfig();
  const favicon = document.getElementById("favicon");

  useEffect(() => {
    if (!config.data && !config.pending) {
      getConfig();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (favicon && config.data && config.data.favicon)
      favicon.href = config.data.favicon;
  }, [config, favicon]);

  const renderApp = () => {
    return (
      <CustomBrowserRouter>
        <Switch>
          <Route path="/" component={AdminPage} />
        </Switch>
      </CustomBrowserRouter>
    );
  };

  return !config.error ? (
    <ErrorBoundary>
      <AppContextProvider config={config}>
        {config.pending && <Loader />}
        {!config.pending && config.data && renderApp()}
      </AppContextProvider>
    </ErrorBoundary>
  ) : (
    <div className="error-container">
      エラーが発生しました。 ページを更新してください。
    </div>
  );
};

export default App;
