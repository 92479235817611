/* eslint-disable no-undef */
import React, { useRef, useEffect, useState } from "react";
import "./Mapbox.scss";

const GoogleMap = ({ lat, lng }) => {
  const mapContainer = useRef();
  const map = useRef();
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    try {
      if (!map.current && mapContainer.current) {
        map.current = new google.maps.Map(mapContainer.current, {
          center: {
            lat: lat || null,
            lng: lng || null
          },
          zoom: 18,
          clickableIcons: false,
          disableDefaultUI: true,
          gestureHandling: "none",
          draggable: false,
          zoomControl: true
        });

        const el = document.createElement("div");
        const spanEl = document.createElement("span");
        el.className = "pin-no-animation";
        spanEl.className = "pulse-no-delay";
        el.style.backgroundColor = "var(--theme-primary-color)";
        el.appendChild(spanEl);
        mapContainer.current.appendChild(el);
      }
    } catch (error) {
      setHasError(true);
    }
  }, [hasError, lat, lng]);

  return !hasError ? (
    <div
      className="map-container map-container google"
      ref={el => (mapContainer.current = el)}
    />
  ) : (
    <div className="error-color">地図を読み込めません</div>
  );
};

export default GoogleMap;
