import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../../context/AppContext";
import { GetArsLink } from "../../../helpers/Api";
import { useRouterMatch } from "../../../helpers/Hooks";

import "./ActionButtons.scss";

const ActionButtons = () => {

  const [arsLink, getArsLink] = GetArsLink();
  const { errorDispatch, translations, claimDetailsState } = useContext(AppContext);
  const [arsClick, setArsclick] = useState(false);

  const { params } = useRouterMatch("/:guid");

  useEffect(() => {
    if (arsLink.complete) {
      errorDispatch({
        type: "update",
        value: arsLink.error
      });

      if (arsLink.error === false && arsLink.data && arsLink.data.link) {
        setArsclick(true);
        var win = window.open(arsLink.data.link, "_blank");
        win.focus();
      }
    }

  }, [arsLink, errorDispatch]);

  return (
    <div
      disabled={!params}
      className="action-buttons-container"
    >
      <button
        disabled={!params}
        onClick={() => {
          if ((arsClick && !arsLink.error) || claimDetailsState.sent) return;
          if (params && params.guid) {
            getArsLink(params.guid);
          }
        }}
        className={"ars-link-button" + ((arsClick && !arsLink.error) || claimDetailsState.sent ? " complete" : "")}
        type="button"
      >
        <span className={(arsClick && !arsLink.error) || claimDetailsState.sent ? "tracking-out-contract-bck" : ""}>
          {translations.phoneSearchBar.claimSystemButton}
        </span>
        <div className={"checkmark draw" + ((arsClick && !arsLink.error) || claimDetailsState.sent ? " show" : " hide")} />
      </button>
    </div>
  );
};

export default ActionButtons;
