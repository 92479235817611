import {useEndpoint} from "./Hooks";

const getLang = lang => {
  if (lang) {
    sessionStorage.setItem("lang", lang);
    headers["Accept-Language"] = lang;
    return headers;
  }
  return headers;
};

const headers = {
  "Content-Type": "application/json",
  "Accept-Language": sessionStorage.getItem("lang")
    ? sessionStorage.getItem("lang")
    : navigator.language || navigator.userLanguage
};

export const GetConfig = () => {
  return useEndpoint(lang => ({
    url: `/api/config`,
    method: "GET",
    headers: lang ? getLang(lang) : headers
  }));
};

export const GetFlowDetails = () => {
  return useEndpoint(id => ({
    url: `/api/flow?id=${id}`,
    method: "GET",
    headers: headers
  }));
};

export const GetClaimByPhoneNumber = () => {
  return useEndpoint(phoneNumber => {
    if (!phoneNumber) return;
      return ({
        url: `/api/search?phoneNumber=${phoneNumber}`,
        method: "GET",
        headers: headers
      });
    }
  );
};

export const GetArsLink = () => {
  return useEndpoint(guid => ({
    url: `/api/ars?guid=${guid}`,
    method: "GET",
    headers: headers
  }));
};
