import React, { useContext, useEffect } from "react";
import "./AdminDash.scss";
import { useRouter } from "../../helpers/Hooks";

import { Route, Switch } from "react-router-dom";
import ClaimOverview from "../ClaimOverview/ClaimOverview";
import ClaimDetails from "../ClaimDetails/ClaimDetails";
import Header from "../../components/Header/Header";
import Error from "../../components/SubmitError/Error";
import { AppContext } from "../../context/AppContext";

const DashboardPage = () => {
  const { phoneSearchResultState } = useContext(AppContext);
  const router = useRouter();

  useEffect(() => {
    if (phoneSearchResultState && phoneSearchResultState.routeTo) {
      router.history.push(phoneSearchResultState.routeTo);
    }
  }, [phoneSearchResultState, router.history]);

  return (
    <div className="page-container">
      <Error />
      <div className="page-header">
        <Header />
      </div>
      <Switch>
        <Route path="/" component={ClaimOverview} exact />
        <Route path="/:claimId" component={ClaimDetails} />
      </Switch>
    </div>
  );
};

export default DashboardPage;
